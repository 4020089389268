/*= BootStrap =*/
.containerHalf{
    max-width: 545px;
    width: 100%;
}
.row {
	margin-right: -15px;
	margin-left: -15px;
}
.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
	max-width: 1170px;
	width: 100%;
}
@media (max-width: 1200px) {
    .container {
        max-width: 970px;
    }
    .containerHalf{
        max-width: 450px;
    }
}
@media (max-width: 992px) {
    .container {
        max-width: 750px;
    }
    .containerHalf{
        max-width: 340px;
    }
}
.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
	float: left;
}
.col-lg-12 {
	width: 100%;
}
.col-lg-11 {
	width: 91.66666667%;
}
.col-lg-10 {
	width: 83.33333333%;
}
.col-lg-9 {
	width: 75%;
}
.col-lg-8 {
	width: 66.66666667%;
}
.col-lg-7 {
	width: 58.33333333%;
}
.col-lg-6 {
	width: 50%;
}
.col-lg-5 {
	width: 41.66666667%;
}
.col-lg-4 {
	width: 33.33333333%;
}
.col-lg-3 {
	width: 25%;
}
.col-lg-2 {
	width: 16.66666667%;
}
.col-lg-1 {
	width: 8.33333333%;
}
@media (max-width: 1200px) {
	.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
		float: left;
	}
	.col-md-12 {
		width: 100%;
	}
	.col-md-11 {
		width: 91.66666667%;
	}
	.col-md-10 {
		width: 83.33333333%;
	}
	.col-md-9 {
		width: 75%;
	}
	.col-md-8 {
		width: 66.66666667%;
	}
	.col-md-7 {
		width: 58.33333333%;
	}
	.col-md-6 {
		width: 50%;
	}
	.col-md-5 {
		width: 41.66666667%;
	}
	.col-md-4 {
		width: 33.33333333%;
	}
	.col-md-3 {
		width: 25%;
	}
	.col-md-2 {
		width: 16.66666667%;
	}
	.col-md-1 {
		width: 8.33333333%;
	}
}
@media (max-width: 992px) {
	.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
		float: left;
	}
	.col-sm-12 {
		width: 100%;
	}
	.col-sm-11 {
		width: 91.66666667%;
	}
	.col-sm-10 {
		width: 83.33333333%;
	}
	.col-sm-9 {
		width: 75%;
	}
	.col-sm-8 {
		width: 66.66666667%;
	}
	.col-sm-7 {
		width: 58.33333333%;
	}
	.col-sm-6 {
		width: 50%;
	}
	.col-sm-5 {
		width: 41.66666667%;
	}
	.col-sm-4 {
		width: 33.33333333%;
	}
	.col-sm-3 {
		width: 25%;
	}
	.col-sm-2 {
		width: 16.66666667%;
	}
	.col-sm-1 {
		width: 8.33333333%;
	}
}
@media screen and (max-width: 768px) {
	.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
		float: left;
	}
	.col-xs-12 {
		width: 100%;
	}
	.col-xs-11 {
		width: 91.66666667%;
	}
	.col-xs-10 {
		width: 83.33333333%;
	}
	.col-xs-9 {
		width: 75%;
	}
	.col-xs-8 {
		width: 66.66666667%;
	}
	.col-xs-7 {
		width: 58.33333333%;
	}
	.col-xs-6 {
		width: 50%;
	}
	.col-xs-5 {
		width: 41.66666667%;
	}
	.col-xs-4 {
		width: 33.33333333%;
	}
	.col-xs-3 {
		width: 25%;
	}
	.col-xs-2 {
		width: 16.66666667%;
	}
	.col-xs-1 {
		width: 8.33333333%;
	}
}
@media screen and (max-width: 640px) {
	.col-xxs-1, .col-xxs-2, .col-xxs-3, .col-xxs-4, .col-xxs-5, .col-xxs-6, .col-xxs-7, .col-xxs-8, .col-xxs-9, .col-xxs-10, .col-xxs-11, .col-xxs-12 {
		float: left;
	}
	.col-xxs-12 {
		width: 100%;
	}
	.col-xxs-11 {
		width: 91.66666667%;
	}
	.col-xxs-10 {
		width: 83.33333333%;
	}
	.col-xxs-9 {
		width: 75%;
	}
	.col-xxs-8 {
		width: 66.66666667%;
	}
	.col-xxs-7 {
		width: 58.33333333%;
	}
	.col-xxs-6 {
		width: 50%;
	}
	.col-xxs-5 {
		width: 41.66666667%;
	}
	.col-xxs-4 {
		width: 33.33333333%;
	}
	.col-xxs-3 {
		width: 25%;
	}
	.col-xxs-2 {
		width: 16.66666667%;
	}
	.col-xxs-1 {
		width: 8.33333333%;
	}
}
/*= BootStrap END =*/

/* Grid Visibles */
// shifted for 1px or 2px
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
	display: none !important;
}
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
	display: none !important;
}
@media (max-width: 640px) {
	.visible-xxs {
		display: block !important;
	}
}
@media (max-width: 768px) {
	.visible-xs {
		display: block !important;
	}
}
@media (min-width: 769px) and (max-width: 992px) {
	.visible-sm {
		display: block !important;
	}
}
@media (min-width: 993px) and (max-width: 1200px) {
	.visible-md {
		display: block !important;
	}
}
@media (min-width: 1201px) {
	.visible-lg {
		display: block !important;
	}
}
@media (max-width: 640px) {
	.hidden-xxs {
		display: none !important;
	}
}
@media (min-width: 640px) and (max-width: 768px) {
	.hidden-xs {
		display: none !important;
	}
}
@media (min-width: 769px) and (max-width: 992px) {
	.hidden-sm {
		display: none !important;
	}
}
@media (min-width: 993px) and (max-width: 1200px) {
	.hidden-md {
		display: none !important;
	}
}
@media (min-width: 1201px) {
	.hidden-lg {
		display: none !important;
	}
}
/* Grid Visibles END*/