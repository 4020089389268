@charset "UTF-8";
/*= BootStrap =*/
.containerHalf {
  max-width: 545px;
  width: 100%; }

.row {
  margin-right: -15px;
  margin-left: -15px; }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1170px;
  width: 100%; }

@media (max-width: 1200px) {
  .container {
    max-width: 970px; }
  .containerHalf {
    max-width: 450px; } }

@media (max-width: 992px) {
  .container {
    max-width: 750px; }
  .containerHalf {
    max-width: 340px; } }

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px; }

.col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
  float: left; }

.col-lg-12 {
  width: 100%; }

.col-lg-11 {
  width: 91.66666667%; }

.col-lg-10 {
  width: 83.33333333%; }

.col-lg-9 {
  width: 75%; }

.col-lg-8 {
  width: 66.66666667%; }

.col-lg-7 {
  width: 58.33333333%; }

.col-lg-6 {
  width: 50%; }

.col-lg-5 {
  width: 41.66666667%; }

.col-lg-4 {
  width: 33.33333333%; }

.col-lg-3 {
  width: 25%; }

.col-lg-2 {
  width: 16.66666667%; }

.col-lg-1 {
  width: 8.33333333%; }

@media (max-width: 1200px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left; }
  .col-md-12 {
    width: 100%; }
  .col-md-11 {
    width: 91.66666667%; }
  .col-md-10 {
    width: 83.33333333%; }
  .col-md-9 {
    width: 75%; }
  .col-md-8 {
    width: 66.66666667%; }
  .col-md-7 {
    width: 58.33333333%; }
  .col-md-6 {
    width: 50%; }
  .col-md-5 {
    width: 41.66666667%; }
  .col-md-4 {
    width: 33.33333333%; }
  .col-md-3 {
    width: 25%; }
  .col-md-2 {
    width: 16.66666667%; }
  .col-md-1 {
    width: 8.33333333%; } }

@media (max-width: 992px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left; }
  .col-sm-12 {
    width: 100%; }
  .col-sm-11 {
    width: 91.66666667%; }
  .col-sm-10 {
    width: 83.33333333%; }
  .col-sm-9 {
    width: 75%; }
  .col-sm-8 {
    width: 66.66666667%; }
  .col-sm-7 {
    width: 58.33333333%; }
  .col-sm-6 {
    width: 50%; }
  .col-sm-5 {
    width: 41.66666667%; }
  .col-sm-4 {
    width: 33.33333333%; }
  .col-sm-3 {
    width: 25%; }
  .col-sm-2 {
    width: 16.66666667%; }
  .col-sm-1 {
    width: 8.33333333%; } }

@media screen and (max-width: 768px) {
  .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
    float: left; }
  .col-xs-12 {
    width: 100%; }
  .col-xs-11 {
    width: 91.66666667%; }
  .col-xs-10 {
    width: 83.33333333%; }
  .col-xs-9 {
    width: 75%; }
  .col-xs-8 {
    width: 66.66666667%; }
  .col-xs-7 {
    width: 58.33333333%; }
  .col-xs-6 {
    width: 50%; }
  .col-xs-5 {
    width: 41.66666667%; }
  .col-xs-4 {
    width: 33.33333333%; }
  .col-xs-3 {
    width: 25%; }
  .col-xs-2 {
    width: 16.66666667%; }
  .col-xs-1 {
    width: 8.33333333%; } }

@media screen and (max-width: 640px) {
  .col-xxs-1, .col-xxs-2, .col-xxs-3, .col-xxs-4, .col-xxs-5, .col-xxs-6, .col-xxs-7, .col-xxs-8, .col-xxs-9, .col-xxs-10, .col-xxs-11, .col-xxs-12 {
    float: left; }
  .col-xxs-12 {
    width: 100%; }
  .col-xxs-11 {
    width: 91.66666667%; }
  .col-xxs-10 {
    width: 83.33333333%; }
  .col-xxs-9 {
    width: 75%; }
  .col-xxs-8 {
    width: 66.66666667%; }
  .col-xxs-7 {
    width: 58.33333333%; }
  .col-xxs-6 {
    width: 50%; }
  .col-xxs-5 {
    width: 41.66666667%; }
  .col-xxs-4 {
    width: 33.33333333%; }
  .col-xxs-3 {
    width: 25%; }
  .col-xxs-2 {
    width: 16.66666667%; }
  .col-xxs-1 {
    width: 8.33333333%; } }

/*= BootStrap END =*/
/* Grid Visibles */
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important; }

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important; }

@media (max-width: 640px) {
  .visible-xxs {
    display: block !important; } }

@media (max-width: 768px) {
  .visible-xs {
    display: block !important; } }

@media (min-width: 769px) and (max-width: 992px) {
  .visible-sm {
    display: block !important; } }

@media (min-width: 993px) and (max-width: 1200px) {
  .visible-md {
    display: block !important; } }

@media (min-width: 1201px) {
  .visible-lg {
    display: block !important; } }

@media (max-width: 640px) {
  .hidden-xxs {
    display: none !important; } }

@media (min-width: 640px) and (max-width: 768px) {
  .hidden-xs {
    display: none !important; } }

@media (min-width: 769px) and (max-width: 992px) {
  .hidden-sm {
    display: none !important; } }

@media (min-width: 993px) and (max-width: 1200px) {
  .hidden-md {
    display: none !important; } }

@media (min-width: 1201px) {
  .hidden-lg {
    display: none !important; } }

/* Grid Visibles END*/
/* Magnific */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }

.mfp-preloader a {
  color: #CCC; }

.mfp-preloader a:hover {
  color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1; }

.mfp-close:active {
  top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #fecc0a;
  font-weight: 600; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }

.mfp-arrow:active {
  margin-top: -54px; }

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1; }

.mfp-arrow:before,
.mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent; }

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px; }

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px; }

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px; }

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px; }

.mfp-iframe-holder .mfp-close {
  top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444; }

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px; }

.mfp-figure figure {
  margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

/* Magnific END */
.lSSlideOuter {
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.lightSlider:before, .lightSlider:after {
  content: " ";
  display: table; }

.lightSlider {
  overflow: hidden;
  margin: 0; }

.lSSlideWrapper {
  max-width: 100%;
  overflow: hidden;
  position: relative; }

.lSSlideWrapper > .lightSlider:after {
  clear: both; }

.lSSlideWrapper .lSSlide {
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
  -webkit-transition: all 1s;
  -webkit-transition-property: -webkit-transform,height;
  -moz-transition-property: -moz-transform,height;
  transition-property: transform,height;
  -webkit-transition-duration: inherit !important;
  transition-duration: inherit !important;
  -webkit-transition-timing-function: inherit !important;
  transition-timing-function: inherit !important; }

.lSSlideWrapper .lSFade {
  position: relative; }

.lSSlideWrapper .lSFade > * {
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 9;
  margin-right: 0;
  width: 100%; }

.lSSlideWrapper.usingCss .lSFade > * {
  opacity: 0;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transition-duration: inherit !important;
  transition-duration: inherit !important;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-timing-function: inherit !important;
  transition-timing-function: inherit !important; }

.lSSlideWrapper .lSFade > *.active {
  z-index: 10; }

.lSSlideWrapper.usingCss .lSFade > *.active {
  opacity: 1; }

/** /!!! End of core css Should not edit !!!/**/
/* Pager */
.lSSlideOuter .lSPager.lSpg {
  margin: 10px 0 0;
  padding: 0;
  text-align: center; }

.lSSlideOuter .lSPager.lSpg > li {
  cursor: pointer;
  display: inline-block;
  padding: 0 5px; }

.lSSlideOuter .lSPager.lSpg > li a {
  background-color: #222222;
  border-radius: 30px;
  display: inline-block;
  height: 8px;
  overflow: hidden;
  text-indent: -999em;
  width: 8px;
  position: relative;
  z-index: 99;
  -webkit-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s; }

.lSSlideOuter .lSPager.lSpg > li:hover a, .lSSlideOuter .lSPager.lSpg > li.active a {
  background-color: #428bca; }

.lSSlideOuter .media {
  opacity: 0.8; }

.lSSlideOuter .media.active {
  opacity: 1; }

/* End of pager */
/** Gallery */
.lSSlideOuter .lSPager.lSGallery {
  list-style: none outside none;
  padding-left: 0;
  margin: 0;
  overflow: hidden;
  transform: translate3d(0px, 0px, 0px);
  -moz-transform: translate3d(0px, 0px, 0px);
  -ms-transform: translate3d(0px, 0px, 0px);
  -webkit-transform: translate3d(0px, 0px, 0px);
  -o-transform: translate3d(0px, 0px, 0px);
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.lSSlideOuter .lSPager.lSGallery li {
  overflow: hidden;
  -webkit-transition: border-radius 0.12s linear 0s 0.35s linear 0s;
  transition: border-radius 0.12s linear 0s 0.35s linear 0s; }

.lSSlideOuter .lSPager.lSGallery li.active, .lSSlideOuter .lSPager.lSGallery li:hover {
  border-radius: 5px; }

.lSSlideOuter .lSPager.lSGallery img {
  display: block;
  height: auto;
  max-width: 100%; }

.lSSlideOuter .lSPager.lSGallery:before, .lSSlideOuter .lSPager.lSGallery:after {
  content: " ";
  display: table; }

.lSSlideOuter .lSPager.lSGallery:after {
  clear: both; }

/* End of Gallery*/
.arrows {
  text-align: right; }

.arrows span {
  color: white;
  font-size: 36px;
  cursor: pointer; }

.arrows span:hover {
  opacity: .5; }

.arrows span:first-child {
  margin-right: 40px; }

.sliderWrap .lSAction {
  display: none !important; }

/* slider actions */
.lSAction > a {
  display: block;
  top: 50%;
  background-image: url("/img/controls.png");
  cursor: pointer;
  position: absolute;
  z-index: 99;
  opacity: 1;
  -webkit-transition: opacity 0.35s linear 0s;
  transition: opacity 0.35s linear 0s;
  color: white;
  font-size: 36px;
  border: none; }

.command .lSAction > a {
  top: 0; }

.command .sliderCommand .lSAction > a {
  top: 25%; }

.lSAction > a:hover {
  opacity: .5;
  color: white; }

.command .lSAction > a i {
  font-size: 40px;
  color: white; }

.command .sliderCommand .lSAction > a i {
  font-size: 60px; }

.command .lSAction > .lSNext {
  right: 0; }

.command .sliderCommand .lSAction > .lSPrev {
  left: -15px;
  right: auto; }

.command .lSAction > .lSPrev {
  right: 100px;
  left: auto; }

.command .sliderCommand .lSAction > .lSNext {
  right: -15px;
  left: auto; }

.lSAction > .lSPrev {
  background-position: 0 0;
  left: -15px; }

.lSAction > .lSNext {
  background-position: -32px 0;
  right: -15px; }

.lSAction > a.disabled {
  pointer-events: none; }

.cS-hidden {
  height: 1px;
  opacity: 0;
  filter: alpha(opacity=0);
  overflow: hidden; }

/* vertical */
.lSSlideOuter.vertical {
  position: relative; }

.lSSlideOuter.vertical.noPager {
  padding-right: 0px !important; }

.lSSlideOuter.vertical .lSGallery {
  position: absolute !important;
  right: 0;
  top: 0; }

.lSSlideOuter.vertical .lightSlider > * {
  width: 100% !important;
  max-width: none !important; }

/* vertical controlls */
.lSSlideOuter.vertical .lSAction > a {
  left: 50%;
  margin-left: -14px;
  margin-top: 0; }

.lSSlideOuter.vertical .lSAction > .lSNext {
  background-position: 31px -31px;
  bottom: 10px;
  top: auto; }

.lSSlideOuter.vertical .lSAction > .lSPrev {
  background-position: 0 -31px;
  bottom: auto;
  top: 10px; }

/* vertical */
/* Rtl */
.lSSlideOuter.lSrtl {
  direction: rtl; }

.lSSlideOuter .lightSlider, .lSSlideOuter .lSPager {
  padding-left: 0;
  list-style: none outside none; }

.lSSlideOuter.lSrtl .lightSlider, .lSSlideOuter.lSrtl .lSPager {
  padding-right: 0; }

.lSSlideOuter .lightSlider > *, .lSSlideOuter .lSGallery li {
  float: left; }

.lSSlideOuter.lSrtl .lightSlider > *, .lSSlideOuter.lSrtl .lSGallery li {
  float: right !important; }

/* Rtl */
@-webkit-keyframes rightEnd {
  0% {
    left: 0; }
  50% {
    left: -15px; }
  100% {
    left: 0; } }

@keyframes rightEnd {
  0% {
    left: 0; }
  50% {
    left: -15px; }
  100% {
    left: 0; } }

@-webkit-keyframes topEnd {
  0% {
    top: 0; }
  50% {
    top: -15px; }
  100% {
    top: 0; } }

@keyframes topEnd {
  0% {
    top: 0; }
  50% {
    top: -15px; }
  100% {
    top: 0; } }

@-webkit-keyframes leftEnd {
  0% {
    left: 0; }
  50% {
    left: 15px; }
  100% {
    left: 0; } }

@keyframes leftEnd {
  0% {
    left: 0; }
  50% {
    left: 15px; }
  100% {
    left: 0; } }

@-webkit-keyframes bottomEnd {
  0% {
    bottom: 0; }
  50% {
    bottom: -15px; }
  100% {
    bottom: 0; } }

@keyframes bottomEnd {
  0% {
    bottom: 0; }
  50% {
    bottom: -15px; }
  100% {
    bottom: 0; } }

.lSSlideOuter .rightEnd {
  -webkit-animation: rightEnd 0.3s;
  animation: rightEnd 0.3s;
  position: relative; }

.lSSlideOuter .leftEnd {
  -webkit-animation: leftEnd 0.3s;
  animation: leftEnd 0.3s;
  position: relative; }

.lSSlideOuter.vertical .rightEnd {
  -webkit-animation: topEnd 0.3s;
  animation: topEnd 0.3s;
  position: relative; }

.lSSlideOuter.vertical .leftEnd {
  -webkit-animation: bottomEnd 0.3s;
  animation: bottomEnd 0.3s;
  position: relative; }

.lSSlideOuter.lSrtl .rightEnd {
  -webkit-animation: leftEnd 0.3s;
  animation: leftEnd 0.3s;
  position: relative; }

.lSSlideOuter.lSrtl .leftEnd {
  -webkit-animation: rightEnd 0.3s;
  animation: rightEnd 0.3s;
  position: relative; }

/*/  GRab cursor */
.lightSlider.lsGrab > * {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab; }

.lightSlider.lsGrabbing > * {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }
